<!--<app-page-heade></app-page-heade>-->
<app-page-heade></app-page-heade>
<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>




<div class="container" style="margin-top:8rem;">
  <div class="row ClaHead">
    <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 mt-2">
      <h3>
        Product Details
      </h3>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
      <!--<div *ngIf="Currency=='Rupees'">
        <span class="click-chip-hover background-grey" style="width: 28%;margin-left: 54px; margin-right: -4px;" routerLinkActive="active">INR </span>
        <span (click)="Currencyy('Dollers')" class="click-chip-hover background-grey"> USD </span>
      </div>
      <div *ngIf="Currency!='Rupees'">
        <span class="click-chip-hover background-grey"  (click)="Currencyy('Rupees')"  style="width: 28%;margin-left: 54px; margin-right: -4px;" routerLinkActive="active">INR </span>
        <div class="click-chip-hover background-grey"> USD </div>
      </div>-->

      <div>
        <span [ngClass]="{'active': isCurrencyActive('Rupees')}" class="click-chip-hover background-grey" (click)="Currencyy('Rupees')" style="width: 28%;margin-left: 54px; margin-right: -4px;">INR</span>
        <span [ngClass]="{'active': isCurrencyActive('Dollars')}" class="click-chip-hover background-grey" (click)="Currencyy('Dollars')">USD</span>
      </div>


    </div>

  </div>

  <div class="row">
    <div class="col-lg-9 col-md-6 col-sm-6 col-xs-9">
      <div class="Head1">
        <span class="hoverr" (click)="CickOnAvraCaralogue()">Products</span> <i style="padding: 8px;" class="fa fa-angle-double-right"></i>
        <span class="hoverr" (click)="ClickOnSplProds()">Aqua Culture</span> <i style="padding: 8px;" class="fa fa-angle-double-right"></i>
        <span class="hoverr" (click)="ClickOnGroupName()">{{GroupName}}</span> <i style="padding: 8px;" class="fa fa-angle-double-right"></i>
        <b>{{ChemicalName}}</b>
        <!--<a (click)="GotoBulk()">BULK Enquiry</a>-->
      </div>
    </div>
    <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12 text-right lang">

      <div id="google_translate_element"></div>


    </div>

    <div class="row">
      <div class="col-lg-12 carddd">
        <div class="card">
          <img src="../../../assets/img/projects/top1.png" class="top-right" alt="Top Image" />
          <img src="../../../assets/img/projects/bottom1.png" class="bottom-left" alt="Bottom Image">
          <div class="row ">
            <div class="col-lg-9 col-md-6 col-sm-6 col-xs-12 mb-3">
              <div class="image-container">
                <img src="assets/img/projects/rect_cleanup-removebg-preview-removebg-preview.png" alt="Your Image" />
                <div class="overlay-text">{{ChemicalName}}</div>
              </div>
            </div>
            <div class="col-lg-1 col-md-6 col-sm-6 col-xs-8 mb-3">
              <div class="image-container">
                <img src="../../../assets/img/projects/fish.png" class="fish" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

              <p class="para"><b>{{AGroupDesc}}</b></p>
              <div class="row mt-5">
                <div class="row borders_odd">
                  <div class="col-lg-3">Catalog No</div>
                  <div class="col-lg-9">{{CatalogNo}}</div>
                </div>
                <div class="row borders_even">
                  <div class="col-lg-3">Class</div>
                  <div class="col-lg-9">{{GroupName}}</div>
                </div>
                <div class="row borders_odd">
                  <div class="col-lg-3">Composition and purity</div>
                  <div class="col-lg-9">{{Compositionandpurity}}</div>
                </div>
                <div class="row borders_even">
                  <div class="col-lg-3">Method of Application</div>
                  <div class="col-lg-9"><p [innerHTML]="Application"></p></div>
                </div>
                <div class="row borders_odd">
                  <div class="col-lg-3">Direction</div>
                  <div class="col-lg-9"><p [innerHTML]="Direction"></p></div>
                </div>


              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="row mb-n3">
                <div class="col-lg-1 col-md-6 col-sm-6 col-xs-12 pack">Pack</div>
                <div class="col-lg-11 col-md-6 col-sm-6 col-xs-12">
                  <div class="row">
                    <div class="col-lg-2" style="cursor:pointer" *ngFor="let packSize of PackSizes; let i = index">
                      <div [id]="'chip-' + (i + 1)"
                           [ngClass]="{'chip': selectedPackSize === packSize, 'chip-outline': selectedPackSize !== packSize}"
                           (click)="selectPackSize(packSize)" style="cursor:pointer">
                        {{ packSize }}
                      </div>
                    </div>
                    <div class="col-lg-6" *ngIf="selectedPackSize" style="cursor:pointer">
                      <div class="btncart" (click)="openEnquiryModal(getItemByPackSize(selectedPackSize))" style="cursor:pointer">
                        <i class="padding_l_1" style="color: #f70be5; cursor: pointer;">
                          <span style="color: black;"></span>
                        </i>
                      Enquiry
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bs-example mt-3">
                <div id="myCarousel2" class="carousel slide" data-interval="6500" data-ride="carousel">
                  <ol class="carousel-indicators">
                    <li data-target="#myCarousel2" data-slide-to="0" class="active"></li>
                    <li data-target="#myCarousel2" data-slide-to="1"></li>

                  </ol>
                  <div class="carousel-inner">
                    <div class="active item carousel-fade">
                      <img src="{{Productexpstructure}}" class="imge d-block w-100" alt="Avra">
                    </div>
                    <div class="item carousel-fade">
                      <img src="{{Applicationprostructure}}" class="imge d-block w-100" alt="Avra">
                    </div>
                  </div>
                </div>
                <div >
                  <!--<h2>No products found. Please fill out the enquiry form:</h2>
    <button (click)="openEnquiryModal()" class="btn btn-primary">click here</button>-->
                  <!-- Modal HTML -->
                  <div class="modal" id="myModal2">
                    <div class="modal-content">
                      <span class="close" (click)="closeModal()">&times;</span>
                      <h2 class="enquriy_h1">Enquiry Form</h2>
                      <form (ngSubmit)="submitEnquiry()">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="name">Name</label>
                              <input type="text" id="name" [(ngModel)]="UserObj.name" name="name" class="form-control" required>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="mobile">Mobile Number</label>
                              <input type="text" (keypress)="keyPress($event)" id="mobile" [(ngModel)]="UserObj.mobile" name="mobile" class="form-control" minlength="10" maxlength="10" required>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="email">Email ID</label>
                              <input type="email" id="email" [(ngModel)]="UserObj.email" name="email" class="form-control" required>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="product">Product Name</label>
                              <input type="text" id="product" [(ngModel)]="ChemicalName" name="product" class="form-control" required readonly>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="CasNumber">Catalog No</label>
                              <input type="text" id="CasNumber" [(ngModel)]="CatalogNo" name="CasNumber" class="form-control" required readonly>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="Quantity">Quantity</label>
                              <input type="text" id="Quantity" [(ngModel)]="UserObj.Quantity" name="Quantity" class="form-control" required>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="form-group">
                              <label for="Description">Description</label>
                              <input type="text" id="Description" [(ngModel)]="UserObj.Description" name="Description" class="form-control">
                            </div>
                          </div>
                        </div>







                        <button type="submit" class="submitt">Submit Enquiry</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

      
          <div class="row ">


            <div class="col-lg-6">
              <div class="bs-example mt-3">
                <div id="myCarousel3" class="carousel slide" data-interval="6500" data-ride="carousel">
                  <ol class="carousel-indicators">
                    <li data-target="#myCarousel3" data-slide-to="0" class="active"></li>
                    <li data-target="#myCarousel3" data-slide-to="1"></li>


                  </ol>
                  <div class="carousel-inner">
                    <div class="active item carousel-fade">
                      <img src="{{molecular_structure_path}}" class="imge d-block w-100" alt="Avra">
                    </div>
                    <div class="item carousel-fade">
                      <img src="{{Productbackstructure}}" class="imge d-block w-100" alt="Avra">
                    </div>


                  </div>
                </div>
              </div>





            </div>
            <div class="col-lg-6 botm">
              <div class="row">

                <div class="row borders_odd">
                  <div class="col-lg-3">Dosage</div>
                  <div class="col-lg-9">
                    <p [innerHTML]="Dosage"></p>
                  </div>
                </div>


                <div class="row borders_even1">
                  <div class="col-lg-3">Storage</div>
                  <div class="col-lg-9">{{Storage}}</div>
                </div>

              </div>
            </div>

          </div>
          <img src="../../../assets/qr-code-eng .webp" class="qr1"/>
          <img src="../../../assets/qr-code-telugu .webp" class="qr2"/>
          <div class="wave">
            <svg viewBox="0 0 500 200">
              <path d="M 0 200 C 150 100 300 200 500 120 L 500 200 L 0 200 Z" fill="#efefef"></path>
              <path d="M 0 200 C 150 100 330 230 500 120 L 500 200 L 0 200 Z" fill="#ffffff" opacity="0.8"></path>
              <path d="M 0 200 C 215 100 250 200 500 140 L 500 200 L 0 200 Z" fill="#efefef" opacity="0.5"></path>
            </svg>

          </div>

        </div>
      </div>

    </div><br />

  </div>

  <!--<script type="text/javascript">
    function googleTranslateElementInit() {
      new google.translate.TranslateElement({
        pageLanguage: 'en',
        includedLanguages: 'hi,bn,te,mr,ta,ur,gu,ml,kn,pa,as,or',
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE
      }, 'google_translate_element');
    }

    function changeLanguage(event) {
      var language = event.target.value;
      var frame = document.querySelector('iframe.goog-te-banner-frame');
      if (frame) {
        var translateButton = frame.contentWindow.document.querySelector('.goog-te-button');
        if (translateButton) {
          translateButton.click();
        }
      }
      var select = document.querySelector('select.goog-te-menu-value');
      if (select) {
        select.value = language;
        var changeEvent = new Event('change');
        select.dispatchEvent(changeEvent);
      }
    }
  </script>
  <script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>-->
