<!--<app-page-heade></app-page-heade>-->
<app-page-heade></app-page-heade>
<div class="container" style="margin-top:8rem;">
  <h3 class="ClaHead">
    Product Classification
    <!--Product Tree-->
  </h3>

  <!--<div class="row padd_1" style="margin-left: -10px;">
    <div class="row">
      <p class="test"> <span class="hoverr"><i class="">&nbsp;&nbsp;</i>Avra Life Sciences Catalogue &nbsp;<i class="fa fa-angle-double-right"></i></span></p>
    </div>
    <div class="row"></div>
    <div class="col-md-6" style="margin-left: -15px;">
      <div class="row" *ngFor="let item of Groups; let i=index" [ngStyle]="{'color' : getColor(item.Name)}">
        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" *ngIf="i==0">
          <img src="../../assets/001.png" style="width:74%;" />
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12" *ngIf="i==1">
          <img src="../../assets/002.png" style="width:74%;" />
        </div>

        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 paragaram">{{item.Name}}</div>
      </div>



    </div>
  </div>-->





  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="test hoverr">Avra Catalogue</div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="row div_row" *ngFor="let item of Groups; let i=index" [ngStyle]="{'color' : getColor(item.Name)}">
        <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 text_align_c">
          <img src="{{item.Icon}}" style="width:74%;" class="image_width">
        </div>
        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 paragaram hoverr" (click)="GoToGroups(item.ID)">{{item.Name}}</div>
      </div>
    </div>
  </div>














</div>






<style>
  .test {
    font-size: 14px;
    letter-spacing: 0.2px;
    margin-bottom: 0.5rem;
  }
</style>







































