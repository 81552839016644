<!--<app-page-heade></app-page-heade>-->

<app-page-heade></app-page-heade>
<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>

<div class="container" style="margin-top:8rem;">
  <h3 class="ClaHead">
    Product Classification
  </h3>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="Head1">
          <span class="hoverr" (click)="CickOnAvraCaralogue()">Avra Catalogue</span> <i style="padding: 8px;" class="fa fa-angle-double-right"></i>
          <span class="hoverr" (click)="ClickOnBiologics()">Biologics</span> <i style="padding: 8px;" class="fa fa-angle-double-right"></i>
          <b>{{igmDesc}}</b>
        </div>
      </div><br />
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 text-align-c">
            <img src="{{Icon}}" style="width:74%;" class="img_width">
          </div>
          <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 paragaram hoverr" style="color:{{Color}}">{{igmDesc}}</div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-6">
        <ul class="sub_head">
          <li class="hoverr" style="margin-bottom: 3px;" *ngFor="let item1 of SubGroups" (click)="OnClickofSubGrp(item1.isgmCode,item1.isgmDesc)">
            <i [ngStyle]="{'color' : getColor(item1.igmDesc)}" class="fa fa-book" aria-hidden="true"></i> {{item1.isgmDesc}}
          </li>
        </ul>
      </div>
    </div>
  </div>



</div>



















































