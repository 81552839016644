<nav class="navbar navbar-inverse navbar-fixed-top opaque-navbar" role="navigation" style="padding-bottom:7%;">

  <div class="container" style="height: 0%;" *ngIf="LoginUserTypeId==''">
    <div style="text-align:right;margin-bottom:0.5rem" class="top_til">
      <!--<a href="https://www.avrasynthesis.com/" target="_blank" style="color:grey"><u>Switch to old website</u></a>-->
      <!-- <a href="https://www.avrasynthesis.com/oldwebsite/" target="_blank" style="color:grey"><u>Switch to old website</u></a>-->
      <!--<a class="fa fa-user-o" routerLink="/customerlogin" routerLinkActive="active"> <strong> Login</strong></a>-->
    </div>



    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse-1">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" routerLink="/Home">
        <img src="../../assets/Avra_Logo_new.png" class="mobile_logo">
      </a>
      <br />
    </div>







    <div class="collapse navbar-collapse" id="navbar-collapse-1">
      <ul class="nav navbar-nav navbar-right">
        <!--<li class="dropdown">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">Products <b class="caret"></b></a>
          <ul class="dropdown-menu" style="cursor:pointer">
            <li class="dropdown dropdown-submenu">
              <a routerLink="/SplChem" routerLinkActive="active">Speciality Chemicals </a>


            </li>
            <li class="dropdown dropdown-submenu">
              <a routerLink="/Biologics" routerLinkActive="active">Biologics </a>
            </li>
          </ul>
        </li>-->

        <li class="hassubs">
          <a routerLink="/Products" (click)="resetSearch()">Products</a>
          <ul class="dropdown">
            <li class="subs"> <a routerLink="/SplChem" routerLinkActive="active">Speciality Chemicals </a></li>
            <li class="subs"> <a routerLink="/Biologics" routerLinkActive="active">Biologics </a></li>
            <li class="subs"> <a routerLink="/Aquaproducts" routerLinkActive="active">Aqua Culture </a></li>
          </ul>
        </li>


        <li>
          <a routerLink="/Downloads" routerLinkActive="active"> Downloads </a>
        </li>

        <!--<li class="hassubs">
          <a routerLink="/ContactUs">Connect</a>
          <ul class="dropdown">
            <li class="subs"> <a routerLink="/ContactUs" routerLinkActive="active"> Connect </a></li>-->
        <!--  <li class="subs"> <a routerLink="/DealersMap" routerLinkActive="active">Dealers Map </a></li>-->
        <!--</ul>
        </li>-->

        <li>
          <a routerLink="/ContactUs" routerLinkActive="active"> Connect </a>
        </li>

        <li>

          <a routerLink="/login" routerLinkActive="active" style="cursor:pointer"> Order </a>

        </li>
        <li (click)="GoToCart()" style="margin-left: -22px;">
          <a routerLinkActive="active"><i class="fa fa-cart-plus padding_l_1" aria-hidden="true" style="color: #f70be5;cursor:pointer"><span style="color:black"></span></i></a>
        </li>
      </ul>
    </div>











    <div style="float:right" class="mobile_search" *ngIf="HomePage!=1">


      <div class="row">
        <div class="col-lg-6" *ngIf="LoginDet != '' && LoginDet != null && LoginDet != undefined">
          >
          <div class="radio">
            <input id="radio-1" name="radio" (click)="Radio(1)" type="radio">
            <label for="radio-1" class="radio-label">Speciality Chemicals</label>
            <input id="radio-2" name="radio" (click)="Radio(2)" type="radio">
            <label for="radio-2" class="radio-label">Biologics</label>
            <input id="radio-3" name="radio" (click)="Radio(3)" type="radio">
            <label for="radio-3" class="radio-label">Aqua Chemicals</label>
          </div>
        </div>
        <div class="col-lg-12">
          <form class="search-container search-wrapper" style="font-size:13px !important;margin-right: 136px;">

            <p-autoComplete name="Value" [(ngModel)]="SearchKeyWord" [suggestions]="FlteredProductsList" (completeMethod)="FilterProducts($event)" field="ChemicalName" [size]="30"
                            (onSelect)="GoToProductsGrid(SearchKeyWord)" scrollHeight="50px" placeholder="CAS No/Catalog No/Chemical Name" [minLength]="1"></p-autoComplete>
            <!--<button type="submit" (click)="GoToProductsGrid(SearchKeyWord)">Search</button>-->
            <button type="submit" (click)="GoToProductsGridWithSearchClick(SearchKeyWord)">Search</button>

          </form>
        </div>
      </div>





    </div>






  </div>



  <div class="container" style="height: 0%;" *ngIf="LoginUserTypeId !=''">
    <div style="text-align:right;margin-top:10px;">

      <!--<a *ngIf="LoginUserTypeId =='1'" href="https://www.avrasynthesis.com/oldwebsite/" target="_blank" style="color:grey"><u>Switch to old website</u></a>-->

      <div>
        <div (click)="LogOut()" routerLinkActive="active" style="cursor:pointer"> {{ContPerson}} | Logout </div>
      </div>
      <!--<div>
        <span (click)="Currency('Rupees')" routerLinkActive="active" style="cursor:pointer">INR  |  </span>
        <span (click)="Currency('Dollers')" routerLinkActive="active" style="cursor:pointer">$ </span>
      </div>-->
      <!--<a class="fa fa-user-o" routerLink="/customerlogin" routerLinkActive="active"> <strong> Login</strong></a>-->
    </div>
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-collapse-1">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" routerLink="/Home">
        <img src="../../assets/Avra_Logo_new.png" class="mobile_logo">
      </a>
      <br />
    </div>



    <div class="collapse navbar-collapse" id="navbar-collapse-1">
      <ul class="nav navbar-nav navbar-right">

        <li class="hassubs">
          <a routerLink="/Products" (click)="resetSearch()">Products</a>
          <ul class="dropdown">
            <li class="subs"> <a routerLink="/SplChem" routerLinkActive="active">Speciality Chemicals </a></li>
            <li class="subs"> <a routerLink="/Biologics" routerLinkActive="active">Biologics </a></li>
            <li class="subs"> <a routerLink="/Aquaproducts" routerLinkActive="active">Aqua Culture </a></li>
          </ul>
        </li>


        <li>
          <a routerLink="/orders" routerLinkActive="active"> My Orders </a>
        </li>
        <li class="hassubs" *ngIf="LoginUserTypeId =='4'||LoginUserTypeId =='5'|| LoginUserTypeId =='6'|| LoginUserTypeId =='7'|| LoginUserTypeId =='8'||LoginUserTypeId =='9'||LoginUserTypeId =='11'">
          <a href="#">Billing</a>
          <ul class="dropdown">
            <li class="subs hassubs">
              <a href="#">Orders</a>
              <ul class="dropdown">
                <li class="subs"><a routerLink="/PendingOrders">Pending Orders</a></li>
                <li class="subs"><a routerLink="/PendingPayments">pending payments</a></li>
                <li class="subs"><a routerLink="/BulkOrderEnquiry">Bulk Enquiry - Order</a></li>

                <li *ngIf="LoginUserTypeId =='11'" class="subs"><a routerLink="/PurchaseOrder">Purchase Order</a></li>

              </ul>

            </li>
            <li class="subs hassubs">
              <a href="#">Accounts Information</a>
              <ul class="dropdown">
                <li class="subs"><a routerLink="/Solvents">Solvents</a></li>
                <li class="subs"><a routerLink="/Invoice">Invoice</a></li>
                <li class="subs"><a routerLink="/Ledger">Ledger</a></li>
              </ul>
            </li>
          </ul>
        </li>



        <li class="hassubs" *ngIf="LoginUserTypeId =='4'||LoginUserTypeId =='5'|| LoginUserTypeId =='6'|| LoginUserTypeId =='7'|| LoginUserTypeId =='8'||LoginUserTypeId =='9'||LoginUserTypeId =='11'">
          <a href="#">Details</a>
          <ul class="dropdown">
            <li class="subs hassubs">
              <a routerLink="/DispatchDetails" routerLinkActive="active"> Dispatch Details  </a>
            </li>
            <li class="subs hassubs">
              <a routerLink="/Achievements" routerLinkActive="active"> Sales & Collections  </a>
            </li>
            <li class="subs hassubs">
              <a routerLink="/CustComplaints" routerLinkActive="active"> Complaints  </a>
            </li>
          </ul>
        </li>



        <!--<li *ngIf="LoginUserTypeId =='4'||LoginUserTypeId =='5'|| LoginUserTypeId =='6'|| LoginUserTypeId =='7'|| LoginUserTypeId =='8'||LoginUserTypeId =='9'||LoginUserTypeId =='11'">
          <a routerLink="/DispatchDetails" routerLinkActive="active"> Dispatch Details  </a>
        </li>
        <li *ngIf="LoginUserTypeId =='4'||LoginUserTypeId =='5'|| LoginUserTypeId =='6'|| LoginUserTypeId =='7'|| LoginUserTypeId =='8'||LoginUserTypeId =='9'||LoginUserTypeId =='11'">
          <a routerLink="/Achievements" routerLinkActive="active"> Sales & Collections  </a>
        </li>

        <li *ngIf="LoginUserTypeId =='4'||LoginUserTypeId =='5'|| LoginUserTypeId =='6'|| LoginUserTypeId =='7'|| LoginUserTypeId =='8'||LoginUserTypeId =='9'||LoginUserTypeId =='11'">
          <a routerLink="/CustComplaints" routerLinkActive="active"> Complaints  </a>
        </li>-->
        <!--<ul *ngIf="LoginUserTypeId =='1'" class="dropdown">
          <li class="subs"> <a routerLink="/Customers" routerLinkActive="active">Customers </a></li>
          <li class="subs"> <a routerLink="/testimonial" routerLinkActive="active">Testimonial </a></li>
        </ul>-->


        <li *ngIf="LoginUserTypeId =='1'">
          <a routerLink="/Customers" routerLinkActive="active"> Customers </a>
        </li>

        <!--<li *ngIf="LoginUserTypeId =='1'">
          <a routerLink="/Testimonial" routerLinkActive="active">Testimonials</a>
        </li>-->
        <li *ngIf="LoginUserTypeId =='1'">
          <a routerLink="/Dealerslist" routerLinkActive="active">Dealer's</a>
        </li>


        <li *ngIf="LoginUserTypeId =='1'||LoginUserTypeId =='5'||LoginUserTypeId =='6'||LoginUserTypeId =='9'||LoginUserTypeId =='10'">
          <a routerLink="/Audit" routerLinkActive="active"> Audit </a>
        </li>
        <li>
          <a routerLink="/Downloads" routerLinkActive="active"> Downloads </a>
        </li>

        <!--li class="hassubs">
          <a routerLink="/ContactUs">Connect</a>
          <ul class="dropdown">
            <li class="subs"> <a routerLink="/ContactUs" routerLinkActive="active"> Connect </a></li>
            <li class="subs"> <a routerLink="/DealersMap" routerLinkActive="active">Dealers Map </a></li>
          </ul>
        </li>-->
        <li>
          <a routerLink="/ContactUs" routerLinkActive="active"> Connect </a>
        </li>

        <li>
          <a routerLink="/FeedBack" routerLinkActive="active"> FeedBack </a>
        </li>
        <li *ngIf="LoginUserTypeId =='1'">
          <a routerLinkActive="active" data-toggle="modal" data-target="#sm-modal"> USD Conversion </a>



        </li>









        <!--<li>
          <a (click)="LogOut()" routerLinkActive="active" style="cursor:pointer"> ({{ContPerson}}) | Logout </a>
        </li>-->
        <li (click)="GoToCart()" style="margin-left: -22px;">



          <a routerLinkActive="active"><i class="fa fa-cart-plus" aria-hidden="true" style="color: #f70be5;cursor:pointer"><span style="color:black">({{count}})</span></i></a>
        </li>
      </ul>








    </div>



    <div *ngIf="HomePage!=1">
      <div class="row">
        <div class="col-lg-8 align-right" *ngIf="LoginDet != '' && LoginDet != null && LoginDet != undefined" >
          <div class="radio" *ngIf="SplChecked">
            <input id="radio-1" name="radio" (click)="Radio(1)" checked type="radio">
            <label for="radio-1" class="radio-label">Speciality/Fine Chemicals</label>
            <input id="radio-2" name="radio" (click)="Radio(2)" type="radio">
            <label for="radio-2" class="radio-label">Biologics</label>
            <input id="radio-3" name="radio" (click)="Radio(3)" type="radio">
            <label for="radio-3" class="radio-label">Aqua Culture</label>
          </div>
          <div class="radio" *ngIf="BioChecked">
            <input id="radio-1" name="radio" (click)="Radio(1)" type="radio">
            <label for="radio-1" class="radio-label">Speciality/Fine Chemicals</label>
            <input id="radio-2" name="radio" (click)="Radio(2)" checked type="radio">
            <label for="radio-2" class="radio-label">Biologics</label>
            <input id="radio-3" name="radio" (click)="Radio(3)" type="radio">
            <label for="radio-3" class="radio-label">Aqua Culture</label>
          </div>
          <div class="radio" *ngIf="AquaChecked">
            <input id="radio-1" name="radio" (click)="Radio(1)" type="radio">
            <label for="radio-1" class="radio-label">Speciality/Fine Chemicals</label>
            <input id="radio-2" name="radio" (click)="Radio(2)" type="radio">
            <label for="radio-2" class="radio-label">Biologics</label>
            <input id="radio-3" name="radio" (click)="Radio(3)" checked type="radio">
            <label for="radio-3" class="radio-label">Aqua Culture</label>
          </div>
        </div>
        <div class="col-lg-4 align-right">
          <form class="search-container search-wrapper aquasearch">

            <p-autoComplete name="Value" [(ngModel)]="SearchKeyWord" [suggestions]="FlteredProductsList" (completeMethod)="FilterProducts($event)" field="ChemicalName" [size]="30"
                            (onSelect)="GoToProductsGrid(SearchKeyWord)" scrollHeight="50px" placeholder="CAS No/Catalog No/Chemical Name" [minLength]="1"></p-autoComplete>
            <!--<button type="submit" (click)="GoToProductsGrid(SearchKeyWord)">Search</button>-->
            <button type="submit" (click)="GoToProductsGridWithSearchClick(SearchKeyWord)">Search</button>
          </form>
        </div>
      </div>





    </div>






  </div>






  <!--<div class="main" *ngIf="LoginUserTypeId !=''">
    <ul class="mainnav">
      <li><a href="#">Home</a></li>
      <li><a href="#">About</a></li>
      <li class="hassubs">
        <a href="#">Services</a>
        <ul class="dropdown">
          <li class="subs"><a href="#">Web Development</a></li>
          <li class="subs"><a href="#">Mobile Apps</a></li>
          <li class="subs hassubs">
            <a href="#">Designing</a>
            <ul class="dropdown">
              <li class="subs"><a href="#">Web Design</a></li>
              <li class="subs"><a href="#">Graphic Design</a></li>
              <li class="subs"><a href="#">Logo Design</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li><a href="#">Gallery</a></li>
      <li class="hassubs">
        <a href="#">Contact</a>
        <ul class="dropdown">
          <li class="subs"><a href="#">Email</a></li>
          <li class="subs hassubs">
            <a href="#">Address</a>
            <ul class="dropdown">
              <li class="subs"><a href="#">Head Office</a></li>
              <li class="subs"><a href="#">Registered Office</a></li>
              <li class="subs"><a href="#">Locate us</a></li>
            </ul>
          </li>
          <li class="subs"><a href="#">Phone</a></li>
        </ul>
      </li>
    </ul>
    <br style="clear: both;">
  </div>-->



</nav>


<!--<button type="button" class="btn btn-secondary mr-1" data-toggle="modal" data-target="#default-modal">Default</button>-->
<div class="modal fade" id="sm-modal" tabindex="-1" role="dialog" aria-labelledby="modal-small-label" style="display: none;">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header state modal-primary" style="background-color:white !important">
        <h4 class="modal-title" id="modal-small-label" style="color:black !important">USD Conversion</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <input name="last_name" placeholder="USD Price" [(ngModel)]="USDPrice" class="form-control" type="text">

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="Avra2020_UpdateFormula()">Update</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>

        <!-- <button type="button" data-dismiss="modal">Ok</button>-->
        <!--<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>-->
      </div>
    </div>
  </div>
</div>



<script>
  // Prevent closing from click inside dropdown
  $(document).on('click', '.dropdown-menu', function (e) {
    e.stopPropagation();
  });

  // make it as accordion for smaller screens
  if ($(window).width() < 992) {
    $('.dropdown-menu a').click(function (e) {
      e.preventDefault();
      if ($(this).next('.submenu').length) {
        $(this).next('.submenu').toggle();
      }
      $('.dropdown').on('hide.bs.dropdown', function () {
        $(this).find('.submenu').hide();
      })
    });
  }

  //  menu code   ///////////////////////
  $(document).on('click', '.dropdown-menu', function (e) {
    e.stopPropagation();
  });

  // make it as accordion for smaller screens
  if ($(window).width() < 992) {
    $('.dropdown-menu a').click(function (e) {
      e.preventDefault();
      if ($(this).next('.submenu').length) {
        $(this).next('.submenu').toggle();
      }
      $('.dropdown').on('hide.bs.dropdown', function () {
        $(this).find('.submenu').hide();
      })
    });
  }
  //  menu code   ///////////////////////
</script>

<style>


  .dropdown-menu > li > a {
    display: block;
    padding: 8px 20px !important;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    letter-spacing: .7px;
    outline: none;
  }


  /* Form wrapper styling */
  .search-wrapper {
    width: 220px;
   /* margin: 45px auto 50px auto;*/
    box-shadow: 0 1px 1px rgba(0, 0, 0, .4) inset, 0 1px 0 rgba(255, 255, 255, .2);
  }


  .dropdown-menu > li > a {
    display: list-item;
    padding: 8px 20px !important;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    letter-spacing: .7px;
    outline: none;
    text-overflow: ellipsis !important;
    width: 250px !important;
    white-space: normal !important;
  }

    .dropdown-menu > li > a:focus, .dropdown-menu > li > a:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
      width: 245px !important;
      cursor: pointer;
    }


  /* Form submit button */
  .search-wrapper button {
    overflow: visible;
    position: relative;
    float: right;
    border: 0;
    padding: 0;
    padding-top: 0.2rem;
    cursor: pointer;
    height: 2.3rem;
    width: 72px;
    color: white;
    text-transform: uppercase;
    background: #8b8b8b;
    border-radius: 0 3px 3px 0;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .3);
    margin-left: -10%;
  }

    .search-wrapper button:hover {
      background: #8b8b8b;
    }

    .search-wrapper button:active,
    .search-wrapper button:focus {
      background: #8b8b8b;
      outline: 0;
    }

    .search-wrapper button:before { /* left arrow */
      content: '';
      position: absolute;
      border-width: 8px 8px 8px 0;
      border-style: solid solid solid none;
      border-color: transparent #8b8b8b transparent;
      top: 9px;
      left: -6px;
    }

    .search-wrapper button:hover:before {
      border-right-color: #8b8b8b;
    }

    .search-wrapper button:focus:before,
    .search-wrapper button:active:before {
      border-right-color: #8b8b8b;
    }

    .search-wrapper button::-moz-focus-inner { /* remove extra button spacing for Mozilla Firefox */
      border: 0;
      padding: 0;
    }


  .search-container {
    width: 100%;
    display: block;
   /* margin: 0 auto;*/
    margin-left: 0%;
   /* margin-right: 100px;*/
  }

  input#search-bar {
    margin: 0 auto;
    width: 100%;
    height: 31px;
    padding: 0 20px;
    font-size: 50px;
    border: 1px solid #D0CFCE;
    outline: none;
    border: 1px solid #008ABF;
    transition: 0.35s ease;
    color: #008ABF;
  }

  .opaque-navbar.opaque {
    background-color: #fff;
    height: 93px;
    transition: background-color .5s ease 0s;
    box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
  }

  .search-icon {
    position: relative;
    float: right;
    width: 75px;
    height: 75px;
    top: -52px;
    right: -22px;
  }



  .active {
    color: #d7127b !important;
  }

  .mobile_logo {
    width: 85%;
  }


  .dropdown-menu > li.kopie > a {
    padding-left: 5px;
  }

  .dropdown-submenu {
    position: relative;
  }

    .dropdown-submenu > .dropdown-menu {
      top: 0;
      left: 100%;
      margin-top: -6px;
      margin-left: -1px;
      -webkit-border-radius: 0 6px 6px 6px;
      -moz-border-radius: 0 6px 6px 6px;
      border-radius: 0 6px 6px 6px;
    }

    .dropdown-submenu > a:after {
      border-color: transparent transparent transparent #333;
      border-style: solid;
      border-width: 5px 0 5px 5px;
      content: " ";
      display: block;
      float: right;
      height: 0;
      margin-right: -10px;
      margin-top: 5px;
      width: 0;
    }

    .dropdown-submenu:hover > a:after {
      border-left-color: #555;
    }

  .dropdown-menu > li > a:hover, .dropdown-menu > .active > a:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {



    .navbar-nav {
      display: inline;
    }

    .navbar-default .navbar-brand {
      display: inline;
    }

    .navbar-default .navbar-toggle .icon-bar {
      background-color: #fff;
    }

    .navbar-default .navbar-nav .dropdown-menu > li > a {
      color: red;
      background-color: #ccc;
      border-radius: 4px;
      margin-top: 2px;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: #333;
    }

      .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
      .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        background-color: #ccc;
      }

    .navbar-nav .open .dropdown-menu {
      border-bottom: 1px solid white;
      border-radius: 0;
    }

    .dropdown-menu {
      padding-left: 10px;
    }

      .dropdown-menu .dropdown-menu {
        padding-left: 20px;
      }

        .dropdown-menu .dropdown-menu .dropdown-menu {
          padding-left: 30px;
        }


    .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
      color: #9d9d9d;
    }

    li.dropdown.open {
      border: 0px solid red;
    }
  }




  @media (min-width: 768px) {
    ul.nav li:hover > ul.dropdown-menu {
      display: block;
    }

    #navbar {
      text-align: center;
    }
  }

  .navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:focus, .navbar-inverse .navbar-nav > .active > a:hover {
    color: #000 !important;
    background-color: #000 !important;
  }


  .navbar-inverse .navbar-nav > li > a {
    color: #000;
  }

  .navbar-right {
    float: right !important;
    margin-right: -15px;
    margin-top: 1% !important;
  }

  .navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:focus, .navbar-inverse .navbar-nav > .open > a:hover {
    color: #000;
    background-color: #fff;
  }

  .navbar-brand {
    float: left;
    height: 50px;
    padding: 0px 10px !important;
    font-size: 18px;
    line-height: 20px;
  }


















  /***********************************************************************
  *  OPAQUE NAVBAR SECTION
  ***********************************************************************/
  .opaque-navbar {
    background-color: rgba(0, 0, 0, 0);
    /* Transparent = rgba(0,0,0,0) / Translucent = (0,0,0,0.5)  */
    height: 93px;
    border-bottom: 0px;
    transition: background-color .5s ease 0s;
    padding-bottom: 9%;
  }

    .opaque-navbar.opaque {
      background-color: #fff;
      height: 140px;
      transition: background-color .5s ease 0s;
      box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
      padding-bottom: 9%;
    }

  ul.dropdown-menu {
    background-color: #fff;
  }

  .opaque-navbar.opaque.navbar-inverse .navbar-nav > li > a {
    color: #000;
  }

  @media (max-width: 992px) {
    body {
      background: red;
    }

    .opaque-navbar {
      background-color: black;
      height: 60px;
      transition: background-color .5s ease 0s;
    }
  }
</style>







<style>
  .dropdown-menu > li > a {
    display: block;
    padding: 8px 20px !important;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    letter-spacing: .7px;
    outline: none;
  }

  ul.dropdown-menu {
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
    outline: none;
  }

  .dropdown-menu > li > a:hover, .dropdown-menu > .active > a:hover {
    text-decoration: none;
  }
</style>


<script>
  $(".dropdown-toggle").on("mouseenter", function () {
    // make sure it is not shown:
    if (!$(this).parent().hasClass("show")) {
      $(this).click();
    }
  });
</script>


<style>
  @media (min-width: 992px) {
    .dropdown-menu .dropdown-toggle:after {
      border-top: .3em solid transparent;
      border-right: 0;
      border-bottom: .3em solid transparent;
      border-left: .3em solid;
    }

    .dropdown-menu .dropdown-menu {
      margin-left: 0;
      margin-right: 0;
    }

    .dropdown-menu li {
      position: relative;
    }

    .nav-item .submenu {
      display: none;
      position: absolute;
      left: 100%;
      top: -7px;
    }

    .nav-item .submenu-left {
      right: 100%;
      left: auto;
    }

    .dropdown-menu > li:hover {
      background-color: #f1f1f1
    }

      .dropdown-menu > li:hover > .submenu {
        display: block;
      }
  }
</style>
