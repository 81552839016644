<!--<app-page-heade></app-page-heade>-->
<app-page-heade></app-page-heade>
<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>

<div class="container" style="margin-top:8rem;">
  <h3 class="ClaHead">
    Product Classification
  </h3>
  <div *ngIf="ShowGroups">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="Head1">
          <span class="hoverr" (click)="CickOnAvraCaralogue()">Avra Catalogue</span><i style="padding: 8px;" class="fa fa-angle-double-right"></i>
          <b>Biologics</b>
        </div>
      </div><br />
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="row" *ngFor="let item of BioGroups; let i=index" [ngStyle]="{'color' : 'item.Color'}">
          <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <img src="{{item.Icon}}" style="width:74%;">
          </div>
          <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 paragaram hoverr" (click)="ClickOnBioGrp(item)">{{item.igmDesc}}</div>
        </div>
      </div>
    </div>
  </div>





</div>



















































