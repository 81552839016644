<!--<app-page-heade></app-page-heade>-->
<app-page-heade></app-page-heade>
<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>




<div class="container" style="margin-top:8rem;">
  <div class="row ClaHead">
    <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 mt-3">
      <h3>
        Product Details
      </h3>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
      <!--<div *ngIf="Currency=='Rupees'">
    <span class="click-chip-hover background-grey" style="width: 28%;margin-left: 54px; margin-right: -4px;" routerLinkActive="active">INR </span>
    <span (click)="Currencyy('Dollers')" class="click-chip-hover background-grey"> USD </span>
  </div>
  <div *ngIf="Currency!='Rupees'">
    <span class="click-chip-hover background-grey"  (click)="Currencyy('Rupees')"  style="width: 28%;margin-left: 54px; margin-right: -4px;" routerLinkActive="active">INR </span>
    <div class="click-chip-hover background-grey"> USD </div>
  </div>-->

      <div>
        <span [ngClass]="{'active': isCurrencyActive('Rupees')}" class="click-chip-hover background-grey" (click)="Currencyy('Rupees')" style="width: 28%;margin-left: 54px; margin-right: -4px;">INR</span>
        <span [ngClass]="{'active': isCurrencyActive('Dollars')}" class="click-chip-hover background-grey" (click)="Currencyy('Dollars')">USD</span>
      </div>


    </div>

  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="Head1">
        <span class="hoverr" (click)="CickOnAvraCaralogue()">Products</span> <i style="padding: 8px;" class="fa fa-angle-double-right"></i>
        <span class="hoverr" (click)="ClickOnSplProds()">Speciality Chemicals</span> <i style="padding: 8px;" class="fa fa-angle-double-right"></i>
        <span class="hoverr" (click)="ClickOnGroupName()">{{GroupName}}</span> <i style="padding: 8px;" class="fa fa-angle-double-right"></i>
        <b>{{ChemicalName}}</b>
        <!--<a (click)="GotoBulk()">BULK Enquiry</a>-->
      </div>
    </div><br />
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mrgn_t_1">

      <div class="Specifications1">{{ChemicalName}}</div>
      <div>{{CatalogNo}}</div>
      <div>

        <img class="MolImg" type="image/tiff" src="{{molecular_structure_path}}" />
      </div>

      <div>
        <table width="100%" height="95%" border="0" align="center" cellpadding="0" cellspacing="0">
          <tbody>

            <tr style="width:100%;">
              <td bgcolor="#efefef" class="bodyTextTitle" style="color: grey;width: 50%;">Item Code</td>
              <td bgcolor="#efefef" class="bodyTextTitle" style="width:50%;">{{CatalogNo}}</td>

            </tr>
            <tr style="width:100%;">
              <td class="bodyTextTitle" style="color: grey;width: 50%;">CAS</td>
              <td class="bodyTextTitle" style="width:50%;">{{Cas}}</td>

            </tr>
            <tr style="width:100%;">
              <td bgcolor="#efefef" class="bodyTextTitle" style="color: grey;width: 50%;">Flash Point</td>
              <td bgcolor="#efefef" class="bodyTextTitle" style="width:50%;">{{FlashPoint}}</td>

            </tr>
            <tr style="width:100%;">
              <td class="bodyTextTitle" style="color: grey;width: 50%;"> Physical State  </td>
              <td class="bodyTextTitle" style="width:50%;">{{PhysicalState}}</td>

            </tr>
            <tr style="width:100%;">
              <td bgcolor="#efefef" class="bodyTextTitle" style="color: grey;width: 50%;"> Molecular Weight</td>
              <td bgcolor="#efefef" class="bodyTextTitle" style="width:50%;">{{molecular_weight}}</td>

            </tr>
            <tr style="width:100%;">
              <td class="bodyTextTitle" style="color: grey;width: 50%;"> Storage   </td>
              <td class="bodyTextTitle" style="width:50%;">{{Storage}}</td>
            </tr>
            <tr style="width:100%;">
              <td bgcolor="#efefef" class="bodyTextTitle" style="color: grey;width: 50%;"> Boiling Point   </td>
              <td bgcolor="#efefef" class="bodyTextTitle" style="width:50%;">{{boiling_point}}</td>

            </tr>
            <tr style="width:100%;">
              <td class="bodyTextTitle" style="color: grey;width: 50%;"> Melting Point   </td>
              <td class="bodyTextTitle" style="width:50%;">{{melting_point}}</td>
            </tr>
            <tr style="width:100%;">
              <td bgcolor="#efefef" class="bodyTextTitle" style="color: grey;width: 50%;"> Density   </td>
              <td bgcolor="#efefef" class="bodyTextTitle" style="width:50%;">{{Density}}</td>
            </tr>
            <tr style="width:100%;">
              <td class="bodyTextTitle" style="color: grey;width: 50%;">Molecular Formula</td>
              <td class="bodyTextTitle" style="width:50%;">{{FormulaTxt}}</td>
            </tr>
            <tr style="width:100%;">
              <td bgcolor="#efefef" class="bodyTextTitle" style="color: grey;width: 50%;"> HsnCode   </td>
              <td bgcolor="#efefef" class="bodyTextTitle" style="width:50%;">{{HSNCODE}}</td>
            </tr>
            <tr style="width:100%;">
              <td class="bodyTextTitle" style="color: grey;width: 50%;">Gst % </td>
              <td class="bodyTextTitle" style="width:50%;">{{GSTPER}}</td>
            </tr>

          </tbody>
        </table>

      </div>


    </div>

    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mrgn_t_2">
      <p class="Specifications1">
        Available Packages<span style="margin-left: 12%;font-size:11px;">*MTO(Make To Order), POR(Price On Request)</span>
      </p>


      <div class="row">
        <table style="width: 94.5%;margin-left: 1rem;">
          <tbody>
            <tr style="border-bottom:1px solid #c1c1c1;padding-bottom:1rem !important;">
              <th style="text-align: right;width: 20%;">Size</th>
              <th *ngIf="Currency!='Rupees'" style="text-align: right;width: 20%;" >Price (USD)</th>
              <th *ngIf="Currency=='Rupees'" style="text-align: right;width: 20%;">Price (Rs.)</th>

     

              <!--<th *ngIf="withoutlogin" style="text-align: right;width: 15%;">Availability</th>-->
              <th *ngIf="!LoginDet" style="text-align: right;width: 11%;">HYD(Q)</th>
              <th *ngIf="!LoginDet" style="text-align: right;width: 10%;">BLG(Q)</th>
              <th *ngIf="!LoginDet" style="text-align: right;width: 10%;">Amd(Q)</th>

              <th *ngIf="WareHouseId=='1'" style="text-align: right;width: 11%;">HYD(Q)</th>
              <th *ngIf="WareHouseId=='2'" style="text-align: right;width: 10%;">BLG(Q)</th>
              <th *ngIf="WareHouseId=='4'" style="text-align: right;width: 10%;">Amd(Q)</th>

              <th *ngIf="WareHouseId=='3'" style="text-align: right;width: 11%;">HYD(Q)</th>
              <th *ngIf="WareHouseId=='3'" style="text-align: right;width: 10%;">BLG(Q)</th>
              <th *ngIf="WareHouseId=='3'" style="text-align: right;width: 10%;">Amd(Q)</th>

              <!---------------------------New Code-------------------------->

              <th *ngIf="WareHouseId=='5'" style="text-align: right;width: 10%;">HYD(Q)</th>
              <th *ngIf="WareHouseId=='5'" style="text-align: right;width: 10%;">BLG(Q)</th>

              <th *ngIf="WareHouseId=='6'" style="text-align: right;width: 10%;">HYD(Q)</th>
              <th *ngIf="WareHouseId=='6'" style="text-align: right;width: 10%;">Amd(Q)</th>

              <th *ngIf="WareHouseId=='7'" style="text-align: right;width: 10%;">BLG(Q)</th>
              <th *ngIf="WareHouseId=='7'" style="text-align: right;width: 10%;">Amd(Q)</th>

              <!--<th style="text-align: right;width: 17%;">Quote</th>-->
              <th style="text-align: right;width: 17%;">Cart/Quote</th>
            </tr>
            <!--====== Not showing Bulk Order to UsertypeiD's (5,6,1) starts=======-->
            <tr *ngFor="let item of ConvertedPrices">
              <td align="right" class="bodyTextTitle" style="color: grey">{{item.PackSize}}</td>
              <td *ngIf="item.Price=='POR'" align="right" class="bodyTextTitle"> {{item.Price}}</td>
              <td *ngIf="item.Price !='POR'" align="right" class="bodyTextTitle"> {{item.Price | number:'1.2-2'}}</td>
              <!--<td *ngIf="withoutlogin && item.Qty>0" align="center" class="bodyTextTitle"> YES</td>
  <td *ngIf="withoutlogin && item.Qty==0" align="center" class="bodyTextTitle"> MTO</td>-->


              <td *ngIf="!LoginDet" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.Qty   |  number:'1.2-2'}}</span> <span *ngIf="item.Bulk!='Bulk'">{{item.Qty   |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.Qty == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>
              <td *ngIf="!LoginDet" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.QtyB  |  number:'1.2-2'}} </span><span *ngIf="item.Bulk!='Bulk'">{{item.QtyB   |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.QtyB == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>

              <td *ngIf="!LoginDet" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.QtyA  |  number:'1.2-2'}}</span> <span *ngIf="item.Bulk!='Bulk'">{{item.QtyA    |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.QtyA == 0">
                  <sup>  <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>
              <td *ngIf="WareHouseId=='1'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{ item.Qty | number:'1.2-2' }}</span><span *ngIf="item.Bulk!='Bulk'">{{ item.Qty | number:'1.0-0' }}</span>
                <ng-container *ngIf="item.Qty == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>

              <td *ngIf="WareHouseId=='2'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.QtyB  |  number:'1.2-2'}} </span><span *ngIf="item.Bulk!='Bulk'">{{item.QtyB   |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.QtyB == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>
              <td *ngIf="WareHouseId=='4'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.QtyA  |  number:'1.2-2'}}</span> <span *ngIf="item.Bulk!='Bulk'">{{item.QtyA    |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.QtyA == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>
              <td *ngIf="WareHouseId=='3'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.Qty   |  number:'1.2-2'}} </span><span *ngIf="item.Bulk!='Bulk'">{{item.Qty   |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.Qty == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>
              <td *ngIf="WareHouseId=='3'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.QtyB  |  number:'1.2-2'}} </span><span *ngIf="item.Bulk!='Bulk'">{{item.QtyB   |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.QtyB == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>
              <td *ngIf="WareHouseId=='3'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.QtyA  |  number:'1.2-2'}} </span><span *ngIf="item.Bulk!='Bulk'">{{item.QtyA  |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.QtyA == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>
              <!--  -----------------------------New code------------------->
              <td *ngIf="WareHouseId=='5'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{ item.Qty | number:'1.2-2' }}</span><span *ngIf="item.Bulk!='Bulk'">{{ item.Qty | number:'1.0-0' }}</span>
                <ng-container *ngIf="item.Qty == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>

              <td *ngIf="WareHouseId=='5'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.QtyB  |  number:'1.2-2'}} </span><span *ngIf="item.Bulk!='Bulk'">{{item.QtyB   |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.QtyB == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>

              <td *ngIf="WareHouseId=='6'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{ item.Qty | number:'1.2-2' }}</span><span *ngIf="item.Bulk!='Bulk'">{{ item.Qty | number:'1.0-0' }}</span>
                <ng-container *ngIf="item.Qty == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>

              <td *ngIf="WareHouseId=='6'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.QtyA  |  number:'1.2-2'}} </span><span *ngIf="item.Bulk!='Bulk'">{{item.QtyA  |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.QtyA == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>

              <td *ngIf="WareHouseId=='7'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.QtyB  |  number:'1.2-2'}} </span><span *ngIf="item.Bulk!='Bulk'">{{item.QtyB   |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.QtyB == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>

              <td *ngIf="WareHouseId=='7'" align="right" class="bodyTextTitle">
                <span *ngIf="item.Bulk=='Bulk'">{{item.QtyA  |  number:'1.2-2'}} </span><span *ngIf="item.Bulk!='Bulk'">{{item.QtyA  |  number:'1.0-0'}}</span>
                <ng-container *ngIf="item.QtyA == 0">
                  <sup> <span class="tooltip-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Coming soon">i</span></sup>
                </ng-container>
              </td>




              <td>
                <!-- Add this modal or any similar UI component to your HTML -->
                <div class="modal" id="myModal2">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title enquriy_h1" id="exampleModalLabel">Choose Your Preferred State</h5>
                        <span class="close" (click)="closeModal()">&times;</span>
                      </div>
                      <div class="modal-body" *ngIf="LoginDet && LoginDet.length > 0">
                        <p>By selecting a facility below, you confirm that all products in your cart will be processed and shipped to the chosen location.</p>
                        <form>
                          <!-- Show Hyderabad and Bangalore if OrderTo is 4 or 7 -->
                          <div class="form-check" *ngIf="LoginDet[0].OrderTo == 4 || LoginDet[0].OrderTo == 7">
                            <div class="row">
                              <div class="col-lg-1 rigt">
                                <input class="form-check-input inpt" type="radio" name="state" id="hyderabad" value="1" (click)="setState(1)">
                              </div>
                              <div class="col-lg-11">
                                <label class="form-check-label" for="hyderabad">
                                  Hyderabad
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="form-check" *ngIf="LoginDet[0].OrderTo == 4 || LoginDet[0].OrderTo == 6 || LoginDet[0].OrderTo == 7">
                            <div class="row">
                              <div class="col-lg-1 rigt">
                                <input class="form-check-input inpt" type="radio" name="state" id="bangalore" value="2" (click)="setState(2)">
                              </div>
                              <div class="col-lg-11">
                                <label class="form-check-label" for="bangalore">
                                  Bangalore
                                </label>
                              </div>
                            </div>
                          </div>
                          <!-- Show Ahmedabad if OrderTo is 5, 6, or 7 -->
                          <div class="form-check" *ngIf="LoginDet[0].OrderTo == 5 || LoginDet[0].OrderTo == 6 || LoginDet[0].OrderTo == 7">
                            <div class="row">
                              <div class="col-lg-1 rigt">
                                <input class="form-check-input inpt" type="radio" name="state" id="ahmedabad" value="3" (click)="setState(3)">
                              </div>
                              <div class="col-lg-11">
                                <label class="form-check-label" for="ahmedabad">
                                  Ahmedabad
                                </label>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div *ngIf="!LoginDet || LoginDet.length === 0">
                        <p>Please log in to select your preferred state.</p>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="submitt" data-dismiss="modal">Cancel</button>
                        <button type="button" class="submitt" (click)="confirmState()">Confirm</button>
                      </div>
                    </div>
                  </div>
                </div>




                <!-- Cart Icon -->
                <div class="icon-cart" (click)="CheckLogin(item)" style="cursor:pointer;text-align:right;">
                  <div class="cart-line-1" style="background-color: #D37ABC"></div>
                  <div class="cart-line-2" style="background-color: #D37ABC"></div>
                  <div class="cart-line-3" style="background-color: #D37ABC"></div>
                  <div class="cart-wheel" style="background-color: #D37ABC"></div>
                </div>





              </td>
            </tr>


          </tbody>

          <!--<div class="button__ enqry" (click)="GotoBulk()"> BULK Enquiry </div>-->


        </table>




      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="button__ enqry" (click)="GotoBulk()"> BULK Enquiry </div>
          <div *ngFor="let item of checkavail" style="text-align:center;"><b>Bulk Availability :&nbsp; {{item.Availability}}</b> </div>
          <div class="note-text">Note: This information was generated half an hour ago.</div>
        </div>

        <!--<div class="col-lg-4" style="margin-top:5%" *ngIf="Currency=='Rupees'">
          <span class="button__ enqry" routerLinkActive="active" style="cursor:pointer">INR  |  </span>
          <span (click)="Currencyy('Dollers')" class="button__ enqry" routerLinkActive="active" style="cursor:pointer"> $ </span>
        </div>
        <div class="col-lg-4" style="margin-top:5%" *ngIf="Currency!='Rupees'">
          <span (click)="Currencyy('Rupees')"  class="button__ enqry" routerLinkActive="active" style="cursor:pointer">INR  |  </span>
          <span  class="button__ enqry" routerLinkActive="active" style="cursor:pointer"> $ </span>
        </div>-->


      </div>
    </div>



  </div><br />









  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mrgn_t_2">
      <p class="Specifications">
        Specifications
      </p>

      <table width="100%" height="95%" border="0" align="center" cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td align="left" valign="top">

              <table width="100%">
                <tbody>
                  <tr *ngFor="let item of GroupProductsSpecDetails;let i=index">
                    <td [ngStyle]="{'background-color' : GetBackColor(i)}" style="color: grey;width:50%;">{{item.Parameter}}</td>
                    <td [ngStyle]="{'background-color' : GetBackColor(i)}" style="width:50%;" class="bodyTextTitle">{{item.SpecificationDetails}}</td>
                  </tr>
                  <tr *ngIf="GroupProductsSpecDetails=='0'">
                    <td><b>No Specifications Found</b></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>


    </div>

    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mrgn_t_2">
      <div class="Specifications1">Data Sheet</div>
      <div>
        <table width="100%">
          <tbody>
            <tr>
              <td width="100%" height="16" valign="top" align="right"></td>
            </tr>
            <tr>
              <td style="color: grey;cursor:pointer">
                <div (click)="TypicalCOA()" style="color:deepskyblue"><u>Typical COA Link</u></div>
              </td>
            </tr>
            <tr>
              <td style="color: grey;cursor:pointer">
                
                <div (click)="SDS()" style="color:deepskyblue"><u>SDS (SAFETY DATA SHEET)</u></div>
              </td>
            </tr>




            <tr>
              <td height="20"></td>
            </tr>

            <tr>
              <td height="5"></td>
            </tr>
            <tr>
              <td class="bodyText">
                <form name="COAMSDSForm">
                  Enter Batch No. for Certificate of Analysis (COA) <br>
                  (Example:N2000338, N1810025)<br><br>

                  <input placeholder="Search COA" [(ngModel)]="BatchNo" name="filename" type="text" id="filename" size="50" class="textInput search_box" style="color: rgb(51, 51, 51);width: 89.3%;">
                  <input type="submit" value=" Go.." class="button" (click)="GetCoaDetails()" style="border-left:0px;">

                </form>
              </td>
            </tr>
          </tbody>
        </table>

      </div>


    </div>


  </div>






</div>

