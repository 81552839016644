<!--<app-page-heade></app-page-heade>-->
<app-page-heade></app-page-heade>
<div class="container" style="margin-top:8rem;">
  <h3 class="ClaHead">
    Product Classification
  </h3>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="Head1">
          <span class="hoverr" (click)="CickOnAvraCaralogue()">Avra Catalogue</span> <i style="padding: 8px;" class="fa fa-angle-double-right"></i>
          <span class="hoverr" (click)="ClickOnSplProds()">Aqua Culture</span> <i style="padding: 8px;" class="fa fa-angle-double-right"></i>
          <b>{{GroupName}}</b>
        </div>
      </div><br />
    </div>
    <div class="row">
      <div class="col-md-6">
        <ul class="sub_head">
          <li class="hoverr" style="margin-bottom: 3px;" *ngFor="let item of GroupProducts" (click)="ClickOnSplProd(item.CatalogNo)">
            <i [ngStyle]="{'color' : '#8eb629'}" class="fa fa-book" aria-hidden="true"></i> {{item.ChemicalName}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div *ngIf="Prodslength==0">
  <h2 style="text-align:center;color:grey">No products found.....</h2>
</div>
<div class="loading-indicator" *ngIf="spinner">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>

